import React from 'react';
import { SEO } from '../components/SEO';
import MainLayout from '../layouts/main';
import { Typography } from '@material-ui/core';
import PageHead from '../components/PageHead';
import Section from '../components/Section';
import styled from '../styled';
import { Link } from 'gatsby';

const PressItem = ({
  img,
  href,
  heading,
  subheading
}: {
  img: string;
  href: string;
  heading: string;
  subheading: string;
}) => {
  return (
    <div>
      <a href={href} target="_blank" rel="nofollow">
        <img src={img} alt={heading} />
      </a>
      <Typography variant="h6" component="h3">
        {heading}
      </Typography>
      <Typography variant="body2" component="p" color="textSecondary">
        {subheading}
      </Typography>
    </div>
  );
};

const PressGrid = styled('div')`
  display: grid;
  justify-content: center;
  text-align: center;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 1200px;
  margin: 0 auto 100px;
  grid-column-gap: ${(p) => p.theme.spacing(8)}px;
  grid-row-gap: ${(p) => p.theme.spacing(12)}px;

  img {
    display: inline-block;
    transform: scale(1);
    max-width: 100%;
    margin: 0 auto ${(p) => p.theme.spacing(2)}px;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    transition: all 0.2s ease-in-out;
    border: 1px solid #eee;
    box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.1),
      2px 5px 4px -1px rgba(0, 0, 0, 0.01);

    &:hover {
      transform: scale(1.05);
      box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.05),
        2px 5px 9px 2px rgba(0, 0, 0, 0.01);
    }
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-row-gap: ${(p) => p.theme.spacing(4)}px;
  }
`;

const Grid = styled('div')`
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;

  img {
    display: inline-block;
    margin: 0 auto;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-row-gap: ${(p) => p.theme.spacing(4)}px;
  }
`;

const Content = styled('div')`
  max-width: 800px;
  margin: 0 auto ${(p) => p.theme.spacing(3)}px auto;
  padding: ${(p) => p.theme.spacing(3)}px;
  display: none;
`;

const FeaturedImage = styled('img')`
  max-width: 140px;
  border-radius: 100%;
  display: block;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const Paragraph = styled('p')`
  font-size: 18px;
  line-height: 36px;
`;

const A = styled('a')`
  border-bottom: 1px solid ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.primary.main};
  transition: 0.1s border-bottom linear;

  &:hover {
    border-bottom: 3px solid ${(p) => p.theme.palette.primary.main};
  }
`;

export default () => (
  <MainLayout>
    <>
      <SEO
        title="About Affilimate"
        description="Learn about Affilimate's mission and team"
        pathname="/about/"
      />
      <PageHead>
        <Typography
          variant="h3"
          gutterBottom
          component="h1"
          style={{
            maxWidth: '600px',
            margin: '0 auto 36px',
            fontWeight: 900
          }}
        >
          We build tools to empower publishers
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          style={{ maxWidth: '550px', margin: '24px auto 12px' }}
        >
          Affilimate was founded on the idea that affiliate publishers could
          generate more far revenue with the right data in their hands.
        </Typography>
      </PageHead>
      <Content>
        <Grid>
          <div>
            <FeaturedImage src="/images/monica-lent.jpg" />
            <Typography
              variant="h5"
              component="p"
              gutterBottom
              style={{ fontWeight: 700 }}
            >
              Monica Lent
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ lineHeight: '28px' }}
              gutterBottom
            >
              Co-founder / Product
            </Typography>
            <Typography variant="body2" component="p">
              Previously an engineering lead at one of Europe's top fintech
              companies, Monica's moonlighting as an affiliate and SEO led her
              to found Affilimate. Today she focuses on product development,
              marketing, and community.
            </Typography>
          </div>
          <div>
            <FeaturedImage src="/images/gernot-hoeflechner.jpg" />
            <Typography
              variant="h5"
              component="p"
              gutterBottom
              style={{ fontWeight: 700 }}
            >
              Gernot Hoeflechner
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ lineHeight: '28px' }}
              gutterBottom
            >
              Co-founder / Engineering
            </Typography>
            <Typography variant="body2" component="p">
              Software engineer and engineering leader from Austria, most
              recently as CTO for an HR startup. Gernot leads the technical
              strategy for Affilimate, and ensures our platform is stable,
              accurate, and fast.
            </Typography>
          </div>
        </Grid>
      </Content>
      <Typography
        variant="h4"
        gutterBottom
        component="h2"
        style={{
          maxWidth: '600px',
          margin: '100px auto 12px',
          fontWeight: 700,
          textAlign: 'center'
        }}
      >
        Press, Interviews, &amp; Reviews
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{
          textAlign: 'center',
          maxWidth: '550px',
          margin: '12px auto 48px'
        }}
      >
        What we have to say, and what others are saying about us
      </Typography>
      <PressGrid>
        <PressItem
          href="https://www.martechrecord.com/post/stream-on-demand-publisher-technology-the-next-innovation-in-affiliate-sponsored-by-awin"
          img="/images/press/martech-record.png"
          heading="MarTech Record"
          subheading="Publisher Technology, The next innovation in affiliate. Sponsored by Awin."
        />
        <PressItem
          href="https://www.nichepursuits.com/monica-lent/"
          img="/images/press/niche-pursuits.png"
          heading="Niche Pursuits"
          subheading="Building a Blog That Earns $10K/mo with Affilimate Cofounder Monica Lent"
        />
        <PressItem
          href="https://geniuslink.com/blog/essential-affiliate-marketing-tools-affilimate/"
          img="/images/press/geniuslink.png"
          heading="Geniuslink"
          subheading="Essential Affiliate Marketing Tools: Affilimate"
        />
        <PressItem
          href="https://www.youtube.com/watch?v=SU4BkkH7wN4&t=1s"
          img="/images/press/niche-site-project.png"
          heading="Niche Site Project"
          subheading="Figure Out How Much Each Page Earns | Affilimate Cofounder Monica Lent"
        />
        <PressItem
          href="http://www.femfounder.co/femfounderstories/monica-lent-interview"
          img="/images/press/femfounder.png"
          heading="FemFounder"
          subheading="'Do something today to bring you closer to your goal' with Monica Lent"
        />
        <PressItem
          href="https://goo.gle/3B98MD5"
          img="/images/press/google-search-central.png"
          heading="Google Search Central"
          subheading="Why should developers learn SEO? With Monica Lent"
        />
      </PressGrid>
    </>
  </MainLayout>
);
